import React, { useEffect } from 'react'
import cx from 'classnames'

import { RenderModules } from 'src/utils/renderModules'
import { SEO } from 'src/components/SEO'
import { Game } from 'src/components/game'

import { useSetPage, useStore } from 'src/context/siteContext'

export interface PageProps {
  pageContext: {
    modules: []
    slug: string
    title: string
    meta: {}
    defaultMeta: {}
  }
  path?: string
  preview?: boolean
}

const Page = ({
  path,
  pageContext,
  preview = false
}: PageProps) => {
  const { theme } = useStore()
  const setPage = useSetPage()
  const {
    title,
    modules,
    defaultMeta,
    slug,
    meta
  } = pageContext

  useEffect(() => {
    setPage(slug)
  }, [])

  const url = slug === 'home' ? '' : path
  return (
    <div className={cx('x site__theme', theme)}>
      {preview && (
        <div className='bcblue ac cw x p1'>This is a Preview</div>
      )}
      <SEO defaultMeta={defaultMeta} defaultTitle={title} metaInfo={meta} pagePath={url} />
      <div className=''>
        {RenderModules(modules)}
      </div>
      {slug === 'home' && (
        <Game />
      )}
    </div>
  )
}

export default Page