import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import wordsearch from 'wordsearch'
import { reverse } from 'lodash'

const wordList = [
  'chile',
  'coriander',
  'cumin',
  'dillweed',
  'habanero',
  'juniper',
  'occo',
  'marjoram',
  'spaghetti',
  'peppercorn',
  'salt',
  'rosemary',
  'saffron',
  'staranise',
  'tarragon'
]


export const Game = () => {
  const [game, setGame] = useState([])
  const [startTurn, setStartTurn] = useState([])
  const [playing, setPlaying] = useState(false)
  const [positions, setCurrentPositions] = useState([])
  const [currentWord, setCurrentWord] = useState('')
  const [correct, setCorrect] = useState([])

  const canvasRef = React.useRef(null);
  const contextRef = React.useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const generateGame = () => {
    const search = wordsearch(wordList, 20, 16)
    // console.log('Search?', search)
    setGame(search.grid)
  }

  useEffect(() => {
    generateGame()
  }, [])

  const listenToWord = (r, c, l) => {
    // console.log('hey what up?', r, c)
    setStartTurn([{ row: r, col: c, letter: l}])
    setCurrentPositions([{ row: r, col: c, letter: l}])
    setCurrentWord(l)
  }

  useEffect(() => {
    // console.log('start turn?', startTurn)
    if (startTurn.length > 0) {
      setPlaying(true)
    }
    window.addEventListener('mouseup', () => {
      // end the turn
      setPlaying(false)
    })
  }, [startTurn])

  useEffect(() => {
    if (wordList.includes(currentWord)) {
      setCorrect([...correct, { p: positions, word: currentWord }])
      setPlaying(false)
    }

  }, [positions])

  const checkIfActive = (r, c) => {
    let highlighted = false
    positions.forEach(item => {
      if (item.row === r && item.col === c) {
        highlighted = true
      }
    })
    return highlighted
  }



  const checkIfCorrect = (r, c) => {
    let highlighted = false
    correct.forEach(positions => {
      positions.p.forEach(item => {
        if (item.row === r && item.col === c) {
          highlighted = true
        }
      })
    })
    return highlighted
  }

  const checkCorrect = (word) => {
    let isCorrect = false
    correct.forEach(answer => {
      if (answer.word === word) {
        isCorrect = true
      }
    })
    return isCorrect
  }

  return (
    <div className='bg--white color--black show--1000'>
      <div className='bt pr3 pl3 bb'>
        <div className='container--1200  mxa'>
          <div className='row gutter--none df'>
            <div className='col c8 p1 c10--800'>
              <span className='courier'>Find all 12 and you'll be our favorite spice girl</span>
            </div>
            {/* <div className='col c6 p1 c4--800 bl tr'>
              <span className='courier'>Time: 2:35</span>
            </div> */}
          </div>
        </div>
      </div>
      <div className='pl3 pr3'>
        <div className='container--1200 mxa'>
          <div className='mt3 pb5'>
            <div className='pr'>
              {game.map((row, r) => (
                <div className='game__row df jcb mb2 aic' key={r}>
                  {row.map((letter, c) => (
                    <button
                      onMouseOver={() => {
                        if (playing) {
                          let allowSet = true
                          positions.forEach(pos => {
                            if (pos.row === r && pos.col === c) {
                              allowSet = false
                            }
                          })
                          if (allowSet) {
                            setCurrentWord(currentWord + letter)
                            setCurrentPositions([...positions, { row: r, col: c, letter: letter }])
                          }
                        }
                      }}
                      onMouseDown={() => listenToWord(r, c, letter)}
                      className={cx('color--black game__button s18 bg--transparent bn', {
                        'playing': checkIfActive(r, c),
                        'correct': checkIfCorrect(r, c)
                      })}
                      key={c}>
                      <span className='courier pr z1 regular'>{letter}</span>
                    </button>
                  ))}
                </div>
              ))}
            </div>
            <div className='mt0 pt3 pb3'>
              <div className='row '>
                <div className='col c5'>
                  <span className='s16 courier'>Words:</span>
                </div>
                
                <div className='col c3'>
                  <span className={cx('s16 courier', {
                    'strike': checkCorrect('chile')
                  })}>Chile</span><br />
                  <span className={cx('s16 courier', {
                    'strike': checkCorrect('coriander')
                  })}>Coriander</span><br />
                  <span className={cx('s16 courier', {
                    'strike': checkCorrect('dillweed')
                  })}>Dill Weed</span><br />
                  <span className={cx('s16 courier', {
                    'strike': checkCorrect('juniper')
                  })}>Juniper</span><br />
                </div>
                <div className='col c3'>
                  <span className={cx('s16 courier', {
                    'strike': checkCorrect('cumin')
                  })}>Cumin</span><br />
                  <span className={cx('s16 courier', {
                    'strike': checkCorrect('marjoram')
                  })}>Marjoram</span><br />
                  <span className={cx('s16 courier', {
                    'strike': checkCorrect('salt')
                  })}>Salt</span><br />
                  <span className={cx('s16 courier', {
                    'strike': checkCorrect('habanero')
                  })}>Habanero</span>
                </div>
                <div className='col c3'>
                  <span className={cx('s16 courier', {
                    'strike': checkCorrect('saffron')
                  })}>Saffron</span><br />
                  <span className={cx('s16 courier', {
                    'strike': checkCorrect('staranise')
                  })}>Star Anise</span><br />
                  <span className={cx('s16 courier', {
                    'strike': checkCorrect('tarragon')
                  })}>Tarragon</span><br />
                  <span className={cx('s16 courier', {
                    'strike': checkCorrect('rosemary')
                  })}>Rosemary</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}